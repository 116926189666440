import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Lottie from "react-lottie"
import AlfieSleeping from "../assets/animation/alfie-sleeping.json"
import Modal from "@mui/material/Modal"
import WaveSvg from "../assets/images/Wave.svg"
import ArrowForwardThinIcon from "@mui/icons-material/ArrowForward"
import Button from "@mui/material/Button"
import BreadCrumbs from "../components/bread-crumbs"
import "./manifesto.scss"

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

class Manifesto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
  }

  componentDidMount() {
    window.analytics.page("Manifesto")
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Manifesto" />
        <BreadCrumbs
          currentPage={"Manifesto"}
          breadCrumbsList={breadCrumbsList}
        />
        <div className="common-detailed-header festo">
          <div
            className="common-detailed-inner"
            style={{ position: "relative" }}
          >
            <div className="common-detailed-pattern-content">
              <h2 className="common-detailed-main-head">Manifesto</h2>
              <p className="common-detailed-para">
                By removing the requirement to stop using all substances, we can
                see the path to reaching our goals more clearly.
              </p>
            </div>
            <div className="manifesto-animation">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AlfieSleeping,
                }}
                height={380}
                width={660}
              />
            </div>
          </div>
        </div>

        <div
          className="manifesto-common-wave-wrapper"
          style={{ backgroundImage: `url(${WaveSvg})` }}
        >
          <div className="wave-bg">
            <div className="manifesto-common-wave-inner">
              <div className="manifesto-list">
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>1</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">Vices are normal</h2>
                    <p className="festo-parah">
                      We’re all altering our mood and our mind every day ー
                      whether it be yoga, video games, chocolate, coffee,
                      whiskey, or cocaine ー some have more consequences than
                      others. Some habits support where you want to go, while
                      others may be holding you back.
                    </p>
                  </div>
                </div>
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>2</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      It’s never too early to start working on yourself.
                    </h2>
                    <p className="festo-parah">
                      You’re nowhere near ‘rock bottom,’ but let’s be honest,
                      you could be doing so much better. Our trained therapists
                      and coaches are ready to help you change your habits and
                      behaviors to help you thrive. If you want to try
                      medications or would prefer peer support, we can help with
                      that too.
                    </p>
                  </div>
                </div>
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>3</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      What you’re going through is hard, but getting help
                      doesn’t have to be.
                    </h2>
                    <p className="festo-parah">
                      We think getting care should be as easy as buying drugs or
                      escaping on social media. That means immediate access to a
                      personalized experience that is actually appealing,
                      culturally relevant, and cost-effective.
                    </p>
                  </div>
                </div>

                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>4</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      It’s helpful to address the big picture, not just your
                      drug use or drinking.
                    </h2>
                    <p className="festo-parah">
                      We recognize you’re a whole person and will help you put
                      together all of the pieces for wellness. When you set
                      goals around reducing your drinking or drug use we’ll also
                      work with you to understand how these goals connect to
                      other things happening in your brain and body.
                    </p>
                  </div>
                </div>

                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>5</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      You have a lifetime of experiences – we aren’t here to
                      judge you for them.{" "}
                    </h2>
                    <p className="festo-parah">
                      We won’t shame you or make you feel like you need to
                      ‘surrender’ to treatment. You're unique, and all of your
                      choices have brought you to where you are today. We accept
                      you, and are here to empower you to set and reach your
                      goals.
                    </p>
                  </div>
                </div>

                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>6</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      Speaking of goals, you set your own.
                    </h2>
                    <p className="festo-parah">
                      We’re not about you getting sober, unless that’s what you
                      want. Your goals can be about whatever you want – whether
                      it’s achieving a life milestone or reducing the number of
                      days you miss work from a bad hangover. You define what
                      you’re working toward and we help you get there.
                    </p>
                  </div>
                </div>

                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>7</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      Science has advanced to your advantage...
                    </h2>
                    <p className="festo-parah">
                      Research shows that different therapeutic interventions
                      help people achieve their goals, deal with trauma, and
                      change their behaviors. When rehab was invented,
                      antibiotics didn’t exist and Babe Ruth still played
                      baseball. We know more about the brain than ever before,
                      but so many treatment approaches are set in the past. We
                      customize your care plan based on your needs.
                    </p>
                  </div>
                </div>
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>8</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">Technology has too.</h2>
                    <p className="festo-parah">
                      Improved tech allows you to learn about our services and
                      meet our providers without the need to set an appointment
                      and tell them your deepest, darkest secrets. Our app takes
                      away the waiting room and awkward interactions.
                    </p>
                  </div>
                </div>
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>9</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      Your progress is our passion.{" "}
                    </h2>
                    <p className="festo-parah">
                      We’ll ask you how you’re progressing toward your goals and
                      about your experience with our services. We aim to delight
                      you – but if we don’t, we’ll make changes. Your voice
                      matters in how we provide care and run our business. Just
                      like you, we’re always looking to improve.
                    </p>
                  </div>
                </div>
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>10</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">Come and go as you please.</h2>
                    <p className="festo-parah">
                      Life happens. We want to be your first stop when you need
                      some help, but you may reach a point when you don’t need
                      our services anymore (which is great!). Don’t worry, we’ll
                      be there if you need our services again. We don’t judge.
                    </p>
                  </div>
                </div>
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>11</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">Trust requires transparency.</h2>
                    <p className="festo-parah">
                      Our business model isn’t built on luring people in. We’ll
                      always tell you what things cost before you start a
                      service, and you can come and go as you please. We’re up
                      front with how we operate and what we believe in.
                    </p>
                  </div>
                </div>
                <div className="single-manifesto">
                  <div className="festo-number">
                    <p>12</p>
                  </div>
                  <div className="festo-content">
                    <h2 className="festo-head">
                      Your life is about so much more than your substance use.
                    </h2>
                    <p className="festo-parah">
                      You're a multidimensional human with many talents, skills,
                      and desires. These are the most interesting things about
                      you. You deserve to maximize them. We can help you reclaim
                      your mental energy for the things that matter to you
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="something-main-wrapper">
            <div className="something-inner">
              <h2 className="something-head">Something for Everyone</h2>
              <p className="something-subText">
                Meet with clinicians or learn on your own
                <br /> Regardless of your goal or where you are in the process,
                <br /> we can help you get there.
              </p>
              <Button
                href="/who-is-it-for/"
                className="new-blue-link"
                color="primary"
              >
                Who it's for
                <ArrowForwardThinIcon />
              </Button>
            </div>
          </div>
        </div>
        <Modal
          className="modalStyle1"
          open={
            this.state.openModal ||
            (typeof window !== "undefined" &&
              window.location &&
              window.location.hash === "#get-started")
          }
          disableScrollLock={true}
        >
          <div />
        </Modal>
      </Layout>
    )
  }
}

export default Manifesto
